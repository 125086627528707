import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import SilverDataDaily from '../../components/silverDataDaily';
import SilverData from '../../components/silverData';
export const _frontmatter = {
  "title": "Precio Plata",
  "image": "../../images/cotizacion_plata.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Cotización diaria plata:`}</h2>
    <p>{`Te mostramos el valor diario de la plata en los últimos 30 días.`}</p>
    <SilverDataDaily mdxType="SilverDataDaily" />
    <br />
    <h2>{`Cotización histórica plata:`}</h2>
    <p>{`Aquí te mostramos la evolución del valor de la plata desde sus inicios. `}</p>
    <SilverData mdxType="SilverData" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      